import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license'
import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import StartupLoading from './auth/StartupLoading'

import PanomicsTheme from './theme'

import AccountMissingPage from './auth/AccountMissingPage'
import { Provider } from './auth/authApiSlice'
import AutoSignInPage from './auth/AutoSignInPage'
import Callback from './auth/Callback'
import RestrictedRoute from './auth/RestrictedRoute'


const Frame = lazy(() => import('./dashboard/Frame'))
const ConsoleHomePage = lazy(() => import('./dashboard/ConsoleHomePage'))
const DeploymentsListPage = lazy(() => import('./deployment/DeploymentListPage'))
const DeploymentPage = lazy(() => import('./deployment/DeploymentPage'))
const PrepareHandshakePage = lazy(() => import('./deployment/PrepareHanshakePage'))

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE)

function App() {

    return (
        <ThemeProvider theme={PanomicsTheme}>
            <Routes>
                <Route
                    index
                    element={
                        <Suspense fallback={<StartupLoading />}>
                            <AutoSignInPage />
                        </Suspense>
                    }
                />
                <Route
                    path='account-missing'
                    element={<AccountMissingPage />}
                />
                <Route
                    path='google/callback'
                    element={
                        <Callback provider={Provider.Google} />
                    }
                />

                <Route element={<RestrictedRoute />}>
                    <Route
                        path='app'
                        element={
                            <Suspense fallback={<></>}>
                                <Frame />
                            </Suspense>
                        }
                    >
                        <Route index element={
                            <Suspense fallback={<></>}>
                                <ConsoleHomePage />
                            </Suspense>
                        } />

                        <Route path='deployment/prepare' element={
                            <Suspense fallback={<></>}>
                                <PrepareHandshakePage />
                            </Suspense>
                        } />

                        <Route path='deployment/:id' element={
                            <Suspense fallback={<></>}>
                                <DeploymentPage />
                            </Suspense>
                        } />

                        <Route path='deployment' element={
                            <Suspense fallback={<></>}>
                                <DeploymentsListPage />
                            </Suspense>
                        } />

                        <Route
                            path='*'
                            element={
                                <main style={{ padding: '1rem' }}>
                                    <p>There is nothing here</p>
                                </main>
                            }
                        />
                    </Route>
                </Route>

                <Route
                    path='*'
                    element={
                        <main style={{ padding: '1rem' }}>
                            <p>There is nothing here. /app might be missing.</p>
                        </main>
                    }
                />
            </Routes>
        </ThemeProvider>
    )
}

export default App
